import { useMemo } from 'react';
import type { Order, PaymentMethodInfo } from '@wilm/common';
import { PaymentMethodType } from '@wilm/common';
import ccType from 'credit-card-type';
import { useFormat } from 'helpers/hooks/useFormat';
import { shippingMethods as shippingMethodsMock } from 'helpers/mocks/mockCommonData';
import { useCart } from 'frontastic';

const useOrderData = (order?: Order) => {
    const { shippingMethods } = useCart();
    const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'orders' });

    const paymentInfo: PaymentMethodInfo = useMemo(() => {
        const payment = order?.payments?.[order?.payments?.length - 1];
        const maskedCard = payment?.maskedCard;
        const paymentState = order?.paymentState;
        const lastDigits = maskedCard?.slice(-4);
        const creditCardType = (maskedCard && ccType(maskedCard)[0]?.type) ?? '';
        let ccLabel = maskedCard
            ? `${creditCardType} ... ${lastDigits}`
            : formatCheckoutMessage({ id: 'payment.label.my.card', defaultMessage: 'My Saved Card' });
        if (paymentState === 'Pending') {
            ccLabel = formatCheckoutMessage({ id: 'payment.label.pending', defaultMessage: 'Pending' });
        }

        const paymentMethodToLabelMap: Record<string, PaymentMethodInfo> = {
            creditcard: {
                type: PaymentMethodType.CARD,
                label: ccLabel
            },
            creditcardwithpayerauthentication: {
                type: PaymentMethodType.CARD,
                label: ccLabel
            },
            invoice: {
                type: PaymentMethodType.INVOICE,
                label: formatCheckoutMessage({ id: 'payment.label.invoice', defaultMessage: 'Invoice' })
            },
            instalment: {
                type: PaymentMethodType.INSTALMENT,
                label:
                    payment?.paymentInstalmentOption +
                    ' ' +
                    formatCheckoutMessage({ id: 'payment.label.instalment', defaultMessage: 'Installments' })
            },
            other: {
                type: PaymentMethodType.EMPTY,
                label: formatCheckoutMessage({ id: 'payment.label.other', defaultMessage: 'Pending payment via Payment Page' })
            }
        };
        return paymentMethodToLabelMap[payment?.paymentMethod?.toLowerCase() ?? 'other'];
    }, [order?.payments]);

    const shippingAddress = useMemo(() => {
        if (order?.shippingAddress) {
            const { streetName, city, postalCode } = order.shippingAddress;
            const label = `${streetName}, ${city}, ${postalCode}`;
            return label;
        }
    }, [order?.shippingAddress]);

    const billingAddress = useMemo(() => {
        if (order?.billingAddress) {
            const { streetName, city, postalCode } = order.billingAddress;
            const label = `${streetName}, ${city}, ${postalCode}`;
            return label;
        }
    }, [order?.billingAddress]);

    const orderNumber = useMemo(() => order?.orderId ?? '', [order?.orderId]);

    const deliveryMethod = useMemo(() => {
        const shippingMethod = shippingMethods.data?.find(method => method.shippingMethodId === order?.shippingInfo?.shippingMethodId);

        const shippingDate = new Date();

        const label = `${shippingDate.toISOString().split('T')[0]} by ${shippingMethod?.name ?? shippingMethodsMock[0].name}`;

        return label;
    }, [order?.shippingInfo?.shippingMethodId, shippingMethods.data]);

    return {
        paymentInfo,
        shippingAddress,
        billingAddress,
        orderNumber,
        deliveryMethod
    };
};
export default useOrderData;
